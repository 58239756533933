<template>
    <v-card>
        <v-card-title>
            Vendas por hora 

            <v-spacer></v-spacer>

            <div style="width: 200px; display: flex" >
                <v-btn
                    outlined
                    @click="changeData"
                >
                    <small>{{$moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY')}}</small>
                    <i class="far fa-calendar-alt"></i>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="getSales"
                >
                    <i class="fas fa-sync-alt"></i>
                </v-btn>
            </div>
        </v-card-title>
        <v-card-text>
            <bar-graph :options="graph.options" :chart-data="graph.chartData" v-if="!overlay" />
        </v-card-text>
        <v-card-actions>
            Total: <strong>{{formater(total)}}</strong>
        </v-card-actions>
        <v-overlay 
            :value="overlay"
            absolute
        >
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>

        <v-dialog
            ref="dialog"
            v-model="dialogDate"
            persistent
            width="360px"
        >
            <v-date-picker
                scrollable
                v-model="data"
            >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="dialogDate = false"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="getSales"
                >
                    Alterar
                </v-btn>
            </v-date-picker>
        </v-dialog>
    </v-card>
</template>
<script>
import { barGraph } from '@/components/graphics/'
import helper from "@/mixins/helper"
import numeric from "@/mixins/numeric"
import { mapGetters } from 'vuex'

export default {
    components: {
        barGraph
    },

    mixins: [helper, numeric],

    data(){
        return {
            total: 0,
            data: null,
            dialogDate: false,
            graph: {
                chartData: {
                    labels: [],
                    datasets: [{
                        label: 'Valor balcão',
                        backgroundColor: '#B71C1C',
                        data: []
                    },{
                        label: 'Valor Delivery',
                        backgroundColor: '#304FFE',
                        data: []
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            },
            overlay: false
        }
    },
    
    computed: {
        ...mapGetters({
            userToken: 'token',
            franchiseID: 'franchiseId',
            franchiseDate: 'franchiseDate'
        })
    },

    methods: {
        async getSales(){
            this.overlay = true
            this.dialogDate = false
            this.total   = 0
            const response = await this.getData(`${process.env.VUE_APP_API}/graphs/sales/news/hours`, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.userToken,
                    'Franchise': this.franchiseID,
                    'FranchiseDate': this.data ? this.$moment(this.data, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''
                })
            })

            console.log(response.response)

            if (response.data) {
                let labels = []
                let values = []
                let count  = []

                let lastPeriod      = null
                let lastPeriodValue = 0
                let lastPeriodQTD   = 0

                response.response.forEach(e => {
                    if (lastPeriod !== e.periodo) {
                        lastPeriod      = e.periodo
                        lastPeriodValue = e.total
                        lastPeriodQTD   = e.qtd
                    }else{
                        lastPeriodValue = lastPeriodValue + e.total
                        lastPeriodQTD   = lastPeriodQTD + e.qtd
                        labels.push(`${e.periodo}H / ${e.periodo + 1}H - Total: ${lastPeriodValue}, Vendas: ${lastPeriodQTD}`)
                    }
                    console.log({
                        lastPeriod      : e.periodo,
                        lastPeriodValue : e.total,
                        lastPeriodQTD   : e.qtd,

                    })

                    if (e.tipoVenda == 'C' || e.tipoVenda == 'B') values.push(e.total)  
                    if (e.tipoVenda == 'E') count.push(e.total)
                })

                console.log(values, count)

                this.graph.chartData.labels = labels
                this.graph.chartData.datasets[0].data = values
                this.graph.chartData.datasets[1].data = count
            } else {
                this.$root.$on('show-exception-alert', response.message)
            }

            this.overlay = false
        },
    
        changeData() {
            this.dialogDate = true
        }
    },
    mounted(){
        this.getSales()
        this.data = this.franchiseDate

        this.$root.$on('reload-all-franchise-graphs', () => {
            this.data = this.franchiseDate

            this.getSales()
        })
    }
}
</script>
