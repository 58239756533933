<template>
	<v-card class="py-1 px-4" >
		<v-card-text class="justify-space-between" style="display: flex" >
			<div>
				<strong>{{title}}</strong>
			</div>

			<div style="display: flex" >
				<slot name="menu" >
				</slot>
			</div>
		</v-card-text>
		<v-card-title class="justify-center" >
			<p>
				<small v-if="pre" >{{pre}}</small> <strong>{{total}}</strong>
			</p>
		</v-card-title>
		<v-card-text class="text-center" >
			<strong>{{text}}</strong>
		</v-card-text>

        <v-overlay 
			:value="overlay" 
			absolute
		>
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>
	</v-card>
</template>

<script>
	export default {
		name: "numberOfClients",
		props: {
			title: {
				Type: String,
				require: true,
			},
			text: {
				Type: String,
				require: true,
			},
			service: {
				Type: String,
				require: true,
			},
			pre: {
				Type: String
			},
			total: {
				Type: Number,
				require: false,
				default: 0
			},
			overlay: {
				Type: Boolean,
				require: false,
				default: false
			}
		},
	};
</script>
<style scoped>
	.v-card__subtitle, .v-card__text{
		padding: 7px;
	}

	.v-card__title{
		font-size: 18px !important;
	}
</style>