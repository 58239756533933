<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                md="4"
                class="home-info-cursor"
            >
                <no-graphics
                    title="Quantidade de vendas" 
                    :text="$moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')" 
                    service="numberofsales"
                    :total="form.data.novasVendas"
                    :overlay="overlay" >
                    <template v-slot:menu >
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                                <v-list-item-title @click="changeDate" >Alterar data</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title @click="showDialog('sales')" >
                                    Ver mais
                                </v-list-item-title>
                            </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </no-graphics>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
                <no-graphics 
                    title="Total de Vendas (R$)" 
                    :text="$moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')"
                    service="totalofsales"
                    :total="form.data.totalVendas"
                    :overlay="overlay" 
                >
                    <template v-slot:menu >
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                                <v-list-item-title @click="changeDate" >Alterar data</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title @click="showDialog('sales')" >
                                    Ver mais
                                </v-list-item-title>
                            </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </no-graphics>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
                <no-graphics 
                    title="Ticket Médio (Vendas)" 
                    :text="$moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')"
                    service="totalofticket"
                    :total="form.data.ticket"
                    :overlay="overlay" 
                >
                    <template v-slot:menu >
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                                <v-list-item-title @click="changeDate" >Alterar data</v-list-item-title>
                            </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </no-graphics>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                md="4"
                class="home-info-cursor"
            >
                <no-graphics
                    title="Novos Clientes Cadastrados" 
                    :text="$moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')"
                    service="numberofclients"
                    :total="form.data.novosClientes"
                    :overlay="overlay" 
                >
                    <template v-slot:menu >
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                                <v-list-item-title @click="changeDate" >Alterar data</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title @click="showDialog('clients')" >
                                    Ver mais
                                </v-list-item-title>
                            </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </no-graphics>
            </v-col>
            <v-col 
                cols="12" 
                md="4" >
                <no-graphics 
                    title="Mesa de maior faturamento" 
                    :text="`${form.data.mesaTotal} - ${$moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`" 
                    service="tablemostvalue"
                    :total="form.data.mesa"
                    :overlay="overlay"
                >
                    <template v-slot:menu >
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                                <v-list-item>
                                    <v-list-item-title @click="changeDate" >Alterar data</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </no-graphics>
            </v-col>

            <v-col 
                cols="12" 
                md="4"
                class="home-info-cursor" >
                <no-graphics 
                    title="Produtos com Estoque (Abaixo do minimo)" 
                    :text="`${$moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`"
                    service="tablevalue" 
                    :total="form.data.minimo"
                    :overlay="overlay" 
                >
                    <template v-slot:menu >
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                                <v-list-item-title @click="changeDate" >Alterar data</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title @click="showDialog('inventory')" >
                                    Ver mais
                                </v-list-item-title>
                            </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </no-graphics>
            </v-col>
        </v-row>

        <!-- Modais -->
        <v-dialog
            v-model="form.dialogs.show"
            scrollable
        >
        <v-card class="py-1 px-4 text-center" >    
            <v-card-title>
                {{form.dialogs[form.dialogs.name].title}}
            </v-card-title>

            <v-card-text>
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th
                                v-for="(title, id) in form.dialogs[form.dialogs.name].titles"
                                :key="`title-${id}`" 
                                class="text-left" 
                            >
                                {{title}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, id) in form.dialogs[form.dialogs.name].data"
                            :key="`product-${id}`"
                        >
                            <td 
                                v-for="(label, lid) in form.dialogs[form.dialogs.name].rows" 
                                :key="`${lid}`"
                                class="text-left" 
                            >{{ item[label] || ' - ' }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>

            <v-card-actions>
                <v-btn @click="close()" >
                    Sair
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <v-dialog
            ref="dialog"
            v-model="dialogDate"
            persistent
            width="360px"
        >
            <v-date-picker
                scrollable
                v-model="date"
            >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="dialogDate = false"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="getHomeData"
                >
                    Alterar
                </v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>

<script>
    import { noGraphics } from '@/components/graphics/'
    import helper from "@/mixins/helper"
    import numeric from "@/mixins/numeric"
    import { mapGetters } from 'vuex'
    export default {
        name: 'homeInfo',
        mixins: [helper, numeric],
        components: {
            noGraphics
        },
        data(){
            return {
                overlay: false,
                date: null,
                dialogDate: false,
                form : {
                    data: {
                        novosClientes: 0,
                        novasVendas: 0,
                        totalVendas: 0,
                        ticket: 0,
                        mesa: 0,
                        mesaTotal: 0,
                        maximo: 0,
                        minimo: 0
                    },
                    dialogs: {
                        show: false,
                        name: 'inventory',
                        inventory: {
                            title: 'Produtos com estoque abaixo do minimo',
                            url: 'grids/inventory/min',
                            titles: ['Descrição', 'Mínimo', 'Disponível'],
                            rows: ['descricao', 'minimo', 'estoqueDisponivel'],
                            data: []
                        },
                        clients: {
                            title: 'Novos clientes',
                            url: 'grids/lastclients',
                            titles: ['Nome', 'Email', 'Telefone'],
                            rows: ['id', 'nome', 'telefone'],
                            data: []
                        },
                        sales: {
                            title: 'Novas vendas',
                            url: 'grids/lastsales',
                            titles: ['Venda', 'Valor', 'Forma de pagamento'],
                            rows: ['venda', 'totalLiquido', 'horaEmissao'],
                            data: []
                        },
                        tables: {
                            title: 'Mesas',
                            url: 'grids/tables',
                            titles: ['Mesa', 'Valor', 'Último pedido'],
                            rows: ['mesa', 'totalLiquido', 'ultimoPedido'],
                            data: []
                        }
                    }
                }
            }
        },
        computed: {
            ...mapGetters({
                userToken: 'token',
                franchiseID: 'franchiseId',
                franchiseDate: 'franchiseDate'
            })
        },
        methods: {
            getHomeData: async function(){
                this.overlay = true
                this.dialogDate = false
                
                const response = await this.getData(`${process.env.VUE_APP_API}/graphs/generics/homebasicinfo`, {
                    method: 'GET',
                    headers: new Headers({
                        'Authentication': this.userToken,
                        'Franchise': this.franchiseID,
                        'FranchiseDate': this.date ? this.$moment(this.date, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''
                    })
                })

                if (response.data) {
                    const res = response.response
                    this.$set(this.form.data, 'novosClientes',  res.novosClientes)
                    this.$set(this.form.data, 'novasVendas',  res.novasVendas)
                    this.$set(this.form.data, 'totalVendas',  this.formater(res.totalVendas))
                    this.$set(this.form.data, 'ticket',  this.formater(res.ticket))
                    this.$set(this.form.data, 'mesa',  res.mesa)
                    this.$set(this.form.data, 'mesaTotal',  this.formater(res.mesaTotal))
                    this.$set(this.form.data, 'maximo',  res.maximo)
                    this.$set(this.form.data, 'minimo',  res.minimo)
                }else{
                    this.$root.$on('show-exception-alert', response.message)
                }

                this.overlay = false
            },

            changeDate() {
                this.dialogDate = true
            },

            showDialog: async function(name) {
                let form = this.form.dialogs
                form.name = name
                form.show = true
                const response = await this.getData(`${process.env.VUE_APP_API}/${form[name].url}`, {
                    method: 'GET',
                    headers: new Headers({
                        'Authentication': this.userToken,
                        'Franchise': this.franchiseID,
                        'FranchiseDate': this.date ? this.$moment(this.date, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''
                    })
                })

                if (response.data)
                    form[name].data = response.response
                else
                    this.$root.$on('show-exception-alert', response.message)
            },

            close() {
                let form = this.form.dialogs
                form.show = false
                form[form.name].value = []
            }
        },
        mounted(){
            this.getHomeData()

            this.date = this.franchiseDate

            this.$root.$on('reload-all-franchise-graphs', () => {
                this.date = this.franchiseDate

                this.getHomeData()
            })
        }
    }
</script>

<style scoped>
    .home-info-cursor{
        cursor: pointer;
    }

    .v-text-field{
        padding: 0PX !important;
        margin: 0px !important;
    }
</style>