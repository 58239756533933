<template>
    <v-card>
        <v-card-title>
            Grupos mais vendidos 

            <v-spacer></v-spacer>

            <div style="width: 200px; display: flex" >
                <v-btn
                    outlined
                    @click="changeData"
                >
                    <small>{{$moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY')}}</small>
                    <i class="far fa-calendar-alt"></i>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="getGroups"
                >
                    <i class="fas fa-sync-alt"></i>
                </v-btn>
            </div>
        </v-card-title>
        <v-card-text>
            <pie-graph :options="graph.options" :chart-data="graph.chartData"  v-if="!overlay"/>
        </v-card-text>
        <v-overlay 
            :value="overlay"
            absolute
        >
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>

        <v-dialog
            ref="dialog"
            v-model="dialogDate"
            persistent
            width="360px"
        >
            <v-date-picker
                scrollable
                v-model="data"
            >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="dialogDate = false"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="getGroups"
                >
                    Alterar
                </v-btn>
            </v-date-picker>
        </v-dialog>
    </v-card>
</template>

<script>
import { pieGraph } from '@/components/graphics/'
import helper from "@/mixins/helper"
import numeric from "@/mixins/numeric"
import { mapGetters } from 'vuex'

export default {
    components: {
        pieGraph
    },

    mixins: [helper, numeric],

    data(){
        return {
            data: null,
            dialogDate: false,
            period: {
                type: 'Meses',
                value: 6,
                service: 'months',
                label: 'mes'
            },
            graph: {
                chartData: {
                    labels: [],
                    datasets: [{
                        label: 'Quantidade',
                        backgroundColor: [],
                        data: [],
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: true,
                        position: 'left',
                        align: 'start',
                    },
                }
            },
            overlay: false
        }
    },
    
    computed: {
        ...mapGetters({
            userToken: 'token',
            franchiseID: 'franchiseId',
            franchiseDate: 'franchiseDate'
        })
    },

    methods: {
        async getGroups(){
            this.overlay = true
            this.dialogDate = false
            const response = await this.getData(`${process.env.VUE_APP_API}/graphs/groups/mostsaleds`, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.userToken,
                    'Franchise': this.franchiseID,
                    'FranchiseDate': this.data ? this.$moment(this.data, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''
                })
            })

            if (response.data) {
                let labels = []
                let datasets = []
                let colors = []
                response.response.forEach(e => {
                    labels.push(e.descricao || 'Sem grupo')
                    datasets.push(e.q)
                    colors.push(this.getColors(colors))
                })

                this.graph.chartData.labels = labels
                this.graph.chartData.datasets[0].data = datasets
                this.graph.chartData.datasets[0].backgroundColor = colors
            }else{
                this.$root.$on('show-exception-alert', response.message)
            }

            this.overlay = false
        },

        changeData() {
            this.dialogDate = true
        }
    },
    mounted(){
        this.getGroups()
        this.data = this.franchiseDate

        this.$root.$on('reload-all-franchise-graphs', () => {
            this.data = this.franchiseDate
            this.getGroups()
        })
    }
}
</script>