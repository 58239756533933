<script>
import { Pie, mixins } from 'vue-chartjs'
export default {
    name: 'barsGraph',
    extends: Pie,
    mixins: [mixins.reactiveProp],
    props: [
        'options',
        'chartData'
    ],

    computed: {
        coisa(){
            return this.chartData
        },

        chartOptions(){
            return this.options
        }
    },

    methods: {
        render(){
            this.renderChart(this.coisa, this.chartOptions)
        }
    },

    mounted () {
        this.render()
    },

    watch: {
        chartData: function(){
            this._chart.destroy()
            this.render()
        }
    }
}
</script>