<template>
	<div style="height: 100%;" >
		<ffranchise v-if="!franchise && !id" />
		<v-container fluid v-if="franchise && id" >
			<v-row>
				<v-col
					cols="12"
				>
					<home-info />
				</v-col>
			</v-row>
			<v-row>
				<v-col
					cols="12"
				>
					<sale-graph />
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<sales-per-hour />
				</v-col>
			</v-row>

			<v-row>
				<v-col
					cols="12"
					md="6"
				>
					<groups-most-saleds />
				</v-col>
				<v-col
					cols="12"
					md="6"
				>
					<products-most-saleds />
				</v-col>
			</v-row>
			<v-row>
				<v-col
					cols="12"
					md="12"
				>
					<payment-methods />
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { saleGraph, salesPerHour, productsMostSaleds, groupsMostSaleds, homeInfo, paymentMethods } from '@/components/views/'
import { ffranchise } from '@/components/ui'
import { mapGetters } from 'vuex'

export default {
	name: "Home",
	components: {
		saleGraph,
		salesPerHour,
		paymentMethods,
		productsMostSaleds,
		groupsMostSaleds,
		ffranchise,
		homeInfo
	},
	computed: {
		...mapGetters({
			franchise: 'franchiseName',
			id: 'franchiseId'
		})
	}
}
</script>
