<script>
import { Bar, mixins } from 'vue-chartjs'
export default {
    name: 'barsGraph',
    extends: Bar,
    mixins: [mixins.reactiveProp],
    props: [
        'options',
        'chartData'
    ],

    methods: {
        render(){
            this.renderChart(this.chartData, this.options)
        }
    },

    mounted () {
        this.render()
        this.$data._chart.update()
    },

    watch: {
        chartData(){
            this.$data._chart.update()
        }
    }
}
</script>