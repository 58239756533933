<template>
    <v-card style="height: 100%; display: flex; flex-direction: column;" >
        <v-card-title>
            Vendas no periodo de {{period.value + ' - ' + period.type}}

            <v-spacer></v-spacer>

            <v-btn 
                outlined
                small
                @click="getValues" 
            >
                <i class="fas fa-sync-alt"></i>
            </v-btn>
        </v-card-title>
        <v-card-text style="height: 100%;" >
            <bar-graph :options="graph.options" :chart-data="graph.chartData" v-if="!overlay" style="height: 100%" />

            <v-overlay 
                :value="overlay"
                absolute
            >
                <v-progress-circular
                    size="100"
                    color="indigo"
                >
                </v-progress-circular>
            </v-overlay>
        </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                    Total: <strong>{{formater(total)}}</strong>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                    <div style="display: flex" class="justify-space-between" >
                        <v-btn
                            outlined
                            small
                            @click="changeService('days', 7)"
                        >
                            7 Dias
                        </v-btn>
                        <v-btn
                            outlined
                            small
                            @click="changeService('days', 15)"
                        >
                            15 Dias
                        </v-btn>
                        <v-btn
                            outlined
                            small
                            @click="changeService('days', 30)"
                        >
                            30 Dias
                        </v-btn>
                        <v-btn
                            outlined
                            small
                            @click="changeService('months', 3)"
                        >
                            3 Meses
                        </v-btn>
                        <v-btn
                            outlined
                            small
                            @click="changeService('months', 6)"
                        >
                            6 Meses
                        </v-btn>
                        <v-btn
                            outlined
                            small
                            @click="changeService('years', 1)"
                        >
                            12 Meses
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
<script>
import { barGraph } from '@/components/graphics/'
import helper from "@/mixins/helper"
import numeric from "@/mixins/numeric"
import { mapGetters } from 'vuex'

const Months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
]

const types = {
    months: {
        value: 'Meses',
        label: 'mes'
    },
    days: {
        value: 'Dias',
        label: 'dia',
    },
    years: {
        value: 'Anos',
        label: 'ano',
    }
}

export default {
    components: {
        barGraph
    },

    mixins: [helper, numeric],

    data(){
        return {
            total: 0,
            period: {
                type: 'Meses',
                value: 6,
                service: 'months',
                label: 'mes'
            },
            graph: {
                chartData: {
                    labels: [],
                    datasets: [{
                        label: 'Valor',
                        backgroundColor: '#B71C1C',
                        data: [],
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            label: (tooltipItem, data) => data.labels[tooltipItem.index]
                        }
                    }  
                }
            },
            overlay: false
        }
    },
    
    computed: {
        ...mapGetters({
            userToken: 'token',
            franchiseID: 'franchiseId'
        })
    },

    methods: {
        async getValues(){
            this.overlay = true
            this.total = 0
            const response = await this.getData(`${process.env.VUE_APP_API}/graphs/sales/news/${this.period.value}/${this.period.service}`, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.userToken,
                    'Franchise': this.franchiseID,
                    'FranchiseDate': null
                })
            })

            if (response.data){
                let labels = []
                let datasets = []

                response.response.forEach(e => {
                    let v = this.formater(e.totalLiquido)
                    this.total = parseFloat(this.total) + parseFloat(e.totalLiquido)
                    let t = ''

                    switch (this.period.label) {
                        case 'mes':
                                t = `${Months[e.mes - 1]}/${e.ano}`
                            break;
                        case 'dia':
                                t = e.emissao
                            break;
                        case 'ano':
                                t = `${Months[e.mes - 1]}/${e.ano}`
                            break;
                    }

                    
                    labels.push(`${t} - ${v}`)
                    datasets.push(e.totalLiquido)
                })

                this.graph.chartData.labels = labels
                this.graph.chartData.datasets[0].data = datasets
            } else {
                this.$root.$on('show-exception-alert', response.message)
            }

            this.overlay = false
        },

        changeService(service, value){
            this.period = {
                type: types[service].value,
                value: value,
                service: service,
                label: types[service].label

            }
            this.getValues()
        }
    },
    mounted(){
        this.getValues()

        this.$root.$on('reload-all-franchise-graphs', () => {
            this.getValues()
        })
    }
}
</script>
